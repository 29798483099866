import { default as _91_46_46_46slug_93U30fMOlfVRMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as addressesM2xnvOXTa5Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as company9ZgD8hQYyhMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contactWiCxtRC53dMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexL87zEXoP5wMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as lists0VhZDGkeolMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91_46_46_46slug_93GmrUMUPjfqMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as ordersKn7slgJ8yiMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billinguf0jAzRxdxMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45userpsTCFm4JOdMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editKJIcCEzB6pMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexdFPHtDXrgBMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newEdeUuGqygNMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as editQAtf07STWTMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexMLW1woP7q3Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profileqhX0ZdItQjMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountL8BofXKbcaMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93PLJ31UpyQjMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as categoryJfSVgmvKj6Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cartrUudWQAXehMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45paymentoOWINFsRtdMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summaryurRkLKt3UqMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkoutXHWwce9sVBMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPassword5Cg6aSVgt6Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordYYUmR8j5YoMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexGV9uBuCdyzMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexNYyV6499ADMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productyyRdBG1cPjMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as register7X1GpNva9AMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolve3BomeUC1YXMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cms84JRBVFZqlMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as storeYo4FXdyTp8Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchX3iYfMkjfbMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectionvuyQK0MC67Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSection1eLJxdfZuCMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexp7fxaUIjLoMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClientDRNI9DxWoOMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElementC2VsBtfwDKMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElement0NDEjKQGEIMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSection7HXlAXsJ65Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeaderk1beu45OHfMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionYgHmzmO0yuMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugEqmR82rKjEMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormQMUOOIowr8Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModalvHFnRYoD71Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugStickyLDLUXAbTQJMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCtai9skh6xYWZMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeftxkYa25b04yMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightrL0QMgHxgpMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPaginations4I7hoUpZzMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionRPnG3syj27Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slideszXpjDOKLUOMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSectionymYpeqC8GVMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePaginationFkLt4cAj6lMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightaQYpF9YEMuMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as indexUCv8LpzvsRMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constantsxfZsgbYz93Meta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPageKKUtzw2zXhMeta } from "/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PageoCIRshTYR4Meta } from "/home/ecomadm/app/releases/189/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93U30fMOlfVRMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-cms/pages/[...slug].vue")
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountL8BofXKbcaMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account.vue"),
    children: [
  {
    name: addressesM2xnvOXTa5Meta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesM2xnvOXTa5Meta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/addresses.vue")
  },
  {
    name: company9ZgD8hQYyhMeta?.name ?? "locale-account-company",
    path: "company",
    meta: company9ZgD8hQYyhMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/company.vue")
  },
  {
    name: contactWiCxtRC53dMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contactWiCxtRC53dMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/contact.vue")
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/index.vue")
  },
  {
    name: lists0VhZDGkeolMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: lists0VhZDGkeolMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/lists.vue")
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    meta: _91_46_46_46slug_93GmrUMUPjfqMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue")
  },
  {
    name: ordersKn7slgJ8yiMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: ordersKn7slgJ8yiMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/orders.vue")
  },
  {
    name: payments_45and_45billinguf0jAzRxdxMeta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billinguf0jAzRxdxMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue")
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45userpsTCFm4JOdMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue")
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editKJIcCEzB6pMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue")
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexdFPHtDXrgBMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue")
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newEdeUuGqygNMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue")
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: editQAtf07STWTMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue")
  },
  {
    name: indexMLW1woP7q3Meta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexMLW1woP7q3Meta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue")
  },
  {
    name: profileqhX0ZdItQjMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profileqhX0ZdItQjMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/account/profile.vue")
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    meta: categoryJfSVgmvKj6Meta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/category.vue"),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93PLJ31UpyQjMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue")
  }
]
  },
  {
    name: checkoutXHWwce9sVBMeta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkoutXHWwce9sVBMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/checkout.vue"),
    children: [
  {
    name: cartrUudWQAXehMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cartrUudWQAXehMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue")
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45paymentoOWINFsRtdMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue")
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summaryurRkLKt3UqMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue")
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPassword5Cg6aSVgt6Meta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue")
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordYYUmR8j5YoMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue")
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/event/index.vue")
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/newsletter/index.vue")
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productyyRdBG1cPjMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/product.vue")
  },
  {
    name: register7X1GpNva9AMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: register7X1GpNva9AMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-customer/pages/[locale]/register.vue")
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolve3BomeUC1YXMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/resolve.vue")
  },
  {
    name: searchX3iYfMkjfbMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchX3iYfMkjfbMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/search.vue"),
    children: [
  {
    name: cms84JRBVFZqlMeta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cms84JRBVFZqlMeta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/search/cms.vue")
  },
  {
    name: storeYo4FXdyTp8Meta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: storeYo4FXdyTp8Meta || {},
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-ecom/pages/[locale]/search/store.vue")
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue")
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue")
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue")
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue")
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts")
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts")
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts")
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts")
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/index.vue")
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js")
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/189/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue")
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/189/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue")
  }
]